import React from 'react'
import { useLocation } from "@reach/router"
import Cusdis from 'gatsby-plugin-cusdis'
import "../../assets/css/main.css";


const Comment = ({ title, id }) => {
  const location = useLocation();
  const cusdisAttrs = {
    pageId: id,
    pageUrl: `http://localhost:8000${location.pathname}` || `https://surprise-movie.party${location.pathname}` || `https://surprise-movie.dev${location.pathname}`,
    pageTitle: title,
  }
  return (
    <div className="main_layout_container">
      <Cusdis attrs={cusdisAttrs} />
    </div>
  )
}

export default Comment;

